import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import Header from "./Header";
import SectionCard from "../../Components/sectioncard";
import { fetchHistoryService } from "../../service/user";
import { toast } from "react-toastify";
import { downloadCSV, jsonToCSV } from "../../utils/csv.utils";

const History = ({ setIsAuth, selectedFilter, setSelectedFilter }) => {
  const { t } = useTranslation(); // Access translation function

  const [expandedItem, setExpandedItem] = useState(null);
  const [history, setHistory] = useState([]);

  const toggleExpand = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };

  const fetchHistory = async () => {
    const data = await fetchHistoryService();
    if (data) {
      const { historyData } = data;
      setHistory(historyData);
    }
  };

  const handleDownloadCSV = (data) => {
    if (!data?.length) {
      toast.error(t("noDataToDownload"));
      return;
    }
    const csvData = jsonToCSV(JSON.parse(data));
    downloadCSV(csvData, t("socialMediaPostsCSV"));
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const camelCaseToSentenceCase = (input) => {
    const spaced = input.replace(/([A-Z])/g, " $1");
    const result =
      spaced.charAt(0).toUpperCase() + spaced.slice(1).toLowerCase();
    return result;
  };

  return (
    <SectionCard
      title={t("history")}
      refresh
      filter
      onSelectFilter={setSelectedFilter}
      className="flex bg-[#4F5AF5] "
      children={
        <div className="px-5 py-8 overflow-y-auto h-[70vh]">
          {history?.length ? (
            history
              .filter((item) => {
                if (selectedFilter?.length && selectedFilter !== "all") {
                  return item.function === selectedFilter;
                } else {
                  return true;
                }
              })
              .map((item) => (
                <button
                  key={item._id}
                  className="w-full bg-[#F3EFF7] rounded-xl p-3 mb-3"
                  onClick={() => toggleExpand(item._id)}
                >
                  <div className="w-full flex justify-between items-center ">
                    <div className="font-bold">
                      <h1 className="text-md">
                        {t(item.function)}
                      </h1>
                      <p className="text-sm font-light text-left">
                        {item.result}
                      </p>
                    </div>
                    <p className="font-bold text-md">{item.time}</p>
                  </div>

                  {expandedItem === item._id &&
                    item.function !== "socialMedia" && (
                      <div className="text-sm font-light text-left">
                        <p>{item.output}</p>
                      </div>
                    )}
                  {item.function === "socialMedia" ? (
                    <div className="flex justify-center items-center w-full py-2">
                      <button
                        className="px-2 gap-3 w-full flex justify-center items-center py-2.5 border border-[#E2E4FB] rounded-full"
                        onClick={() => handleDownloadCSV(item?.output ?? [])}
                      >
                        <img src="assets/download.png" width={20} alt="" />
                        {t("downloadAs")}
                        <img src="assets/xcel.png" width={20} alt="" />
                      </button>
                    </div>
                  ) : (
                    <button
                      className="text-violet-800 text-sm hover:bg-violet-200 bg-opacity-5 py-1.5 px-3 my-2 rounded-full transition duration:300"
                      onClick={() => toggleExpand(item._id)}
                    >
                      {t("seeMore")}
                    </button>
                  )}
                </button>
              ))
          ) : (
            <div className="text-center">
              <p>{t("noHistoryFound")}</p>
            </div>
          )}
        </div>
      }
    />
  );
};

export default History;
