import React, { useState, useEffect } from "react";
import Header from "./Header";
import SectionCard from "../../Components/sectioncard";
import pdfToText from "react-pdftotext";
import { generateReportService } from "../../service/util";
import { FormDropdown, SearchAbleDropDown } from "../../Components/items";
import { FiRefreshCw } from "react-icons/fi";
import { GoUpload } from "react-icons/go";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js'
import { customMarkdownToHTML, removeMarkdownFormatting } from "../../utils/csv.utils";
import { Document, Packer, Paragraph, TextRun } from 'docx';



const languages = [
  { value: 'Albanian', label: 'Albanian' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Armenian', label: 'Armenian' },
  { value: 'Azerbaijani', label: 'Azerbaijani' },
  { value: 'Basque', label: 'Basque' },
  { value: 'Belarusian', label: 'Belarusian' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Bosnian', label: 'Bosnian' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Cantonese', label: 'Cantonese' },
  { value: 'Catalan', label: 'Catalan' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Croatian', label: 'Croatian' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Danish', label: 'Danish' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'English', label: 'English' },
  { value: 'Estonian', label: 'Estonian' },
  { value: 'Faroese', label: 'Faroese' },
  { value: 'Finnish', label: 'Finnish' },
  { value: 'French', label: 'French' },
  { value: 'Galician', label: 'Galician' },
  { value: 'Georgian', label: 'Georgian' },
  { value: 'German', label: 'German' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Irish', label: 'Irish' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Javanese', label: 'Javanese' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Kashmiri', label: 'Kashmiri' },
  { value: 'Kazakh', label: 'Kazakh' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Kyrgyz', label: 'Kyrgyz' },
  { value: 'Latvian', label: 'Latvian' },
  { value: 'Lithuanian', label: 'Lithuanian' },
  { value: 'Malay', label: 'Malay' },
  { value: 'Maltese', label: 'Maltese' },
  { value: 'Mandarin', label: 'Mandarin' },
  { value: 'Marathi', label: 'Marathi' },
  { value: 'Moldovan', label: 'Moldovan' },
  { value: 'Mongolian', label: 'Mongolian' },
  { value: 'Montenegrin', label: 'Montenegrin' },
  { value: 'Nepali', label: 'Nepali' },
  { value: 'Norwegian', label: 'Norwegian' },
  { value: 'Oriya', label: 'Oriya' },
  { value: 'Pashto', label: 'Pashto' },
  { value: 'Persian', label: 'Persian' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Punjabi', label: 'Punjabi' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Sanskrit', label: 'Sanskrit' },
  { value: 'Serbian', label: 'Serbian' },
  { value: 'Sindhi', label: 'Sindhi' },
  { value: 'Sinhala', label: 'Sinhala' },
  { value: 'Slovak', label: 'Slovak' },
  { value: 'Slovene', label: 'Slovene' },
  { value: 'Slovenian', label: 'Slovenian' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Uzbek', label: 'Uzbek' },
  { value: 'Vietnamese', label: 'Vietnamese' },
  { value: 'Welsh', label: 'Welsh' },
  { value: 'Wu', label: 'Wu' },
];


const Report = ({ setIsAuth }) => {
  const [inputText, setInputText] = useState("");
  const { t } = useTranslation(); // Access translation function

  const [targetLanguage, setTargetLanguage] = useState("");
  const [length, setLength] = useState("");
  const [outputText, setOutputText] = useState("");
  const [unFormattedOutputText, setUnFormattedOutputText] = useState("");
  const [isReportComplete, setIsReportComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReportUploaded, setIsReportUploaded] = useState(false);
  const [isFinancial, setIsFinancial] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

  let lengthopt = [
    { value: '200 words', label: '200 words' },
    { value: 'Half page', label: 'Half page' },
    { value: 'One page', label: 'One page' },
    { value: 'Two page', label: 'Two page' },
    { value: 'Two Page+', label: 'Two Page+' }
  ];

  const lang = localStorage.getItem('lang');
  if (lang === 'Hungarian') {
    lengthopt = [
      { value: '200 szó', label: '200 szó' },
      { value: 'Half page', label: 'Fél oldal' },
      { value: 'One page', label: 'Egy oldal ' },
      { value: 'Two page', label: 'Két oldal' },
      { value: 'Two page+', label: 'Két oldal+' }
    ]
  }



  useEffect(() => {
    setIsReportComplete(inputText?.trim() !== "" && targetLanguage !== "" && length !== "");
  }, [inputText, targetLanguage, length]);

  // const formattedReport = outputText.replace(/\n/g, "<br />");

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    await extractText(file);
    setIsReportUploaded(true);
  };

  const extractText = async (file) => {
    try {
      console.log('file: ', file);
      const text = await pdfToText(file);
      console.log('text: ', text);
      setInputText(text);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleReport = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      console.log("Report");
      if (inputText === "" || length === "" || targetLanguage === "") {
        console.log("Please enter some text to analyze.");
        return;
      }
      const data = {
        inputText,
        language: targetLanguage,
        length,
        isFinancial
      };

      const response = await generateReportService(data);
      if (response?.success) {
        console.log("response: ", response);
        setUnFormattedOutputText(response?.reportResponse ?? "");
        const formattedReport = customMarkdownToHTML(response?.reportResponse ?? "");
        setOutputText(formattedReport);
        toast.success(response.message)
      } else {
        console.log("response: ", response);
        toast.error(response.message ?? "Failed to generate report")
        setOutputText("");
        setInputText("");
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    const emailContentDiv = document.getElementById("formattedReport");
    const range = document.createRange();
    range.selectNode(emailContentDiv);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    toast.success("Copied to clipboard");
  };

  const handleDownloadPDF = async () => {
    var element = document.getElementById('topdf');
    html2pdf(element);

    // const { blob, error } = await toPDF({
    //   options: {
    //     margin: [0, 20, 0, 200] // [top, right, bottom, left]
    //   }
    // });
    // if (blob) {
    //   // Create a URL for the blob object
    //   const url = URL.createObjectURL(blob);
    //   // Create a temporary a tag to download the file
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = 'translated_document.pdf';
    //   document.body.appendChild(a);
    //   a.click();
    //   // Clean up
    //   a.remove();
    //   URL.revokeObjectURL(url);
    //   toast.success('PDF downloaded successfully!');
    // } else if (error) {
    //   toast.error('Failed to download PDF');
    //   console.error('PDF download error:', error);
    // }
  };

  const parseHtmlToDocx = (html) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');
    const paragraphs = [];

    parsedHtml.body.childNodes.forEach((node) => {
      if (node.nodeName === 'BR') {
        paragraphs.push(new Paragraph({}));
      } else if (node.nodeName === 'P' || node.nodeName === 'DIV') {
        const children = [];
        node.childNodes.forEach((childNode) => {
          if (childNode.nodeName === '#text') {
            children.push(new TextRun(childNode.textContent));
          } else if (childNode.nodeName === 'B' || childNode.nodeName === 'STRONG') {
            children.push(new TextRun({ text: childNode.textContent, bold: true }));
          } else if (childNode.nodeName === 'I' || childNode.nodeName === 'EM') {
            children.push(new TextRun({ text: childNode.textContent, italics: true }));
          } else if (childNode.nodeName === 'U') {
            children.push(new TextRun({ text: childNode.textContent, underline: {} }));
          } else if (childNode.nodeName === 'BR') {
            paragraphs.push(new Paragraph({ children }));
            children.length = 0; // Reset children array
          }
        });
        paragraphs.push(new Paragraph({ children }));
      } else if (node.nodeName === 'H1') {
        paragraphs.push(new Paragraph({
          children: [new TextRun({ text: node.textContent, bold: true, size: 48 })],
          heading: 'Heading1',
        }));
      } else if (node.nodeName === 'H2') {
        paragraphs.push(new Paragraph({
          children: [new TextRun({ text: node.textContent, bold: true, size: 32 })],
          heading: 'Heading2',
        }));
      } else if (node.nodeName === 'H3') {
        paragraphs.push(new Paragraph({
          children: [new TextRun({ text: node.textContent, bold: true, size: 24 })],
          heading: 'Heading3',
        }));
      } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
        node.childNodes.forEach((listItem) => {
          if (listItem.nodeName === 'LI') {
            paragraphs.push(new Paragraph({
              children: [new TextRun(listItem.textContent)],
              bullet: node.nodeName === 'UL' ? { level: 0 } : undefined,
              numbering: node.nodeName === 'OL' ? { level: 0 } : undefined,
            }));
          }
        });
      }
    });

    return paragraphs;
  };

  const handleDownloadWord = async () => {
    const withOutMarkDown = removeMarkdownFormatting(unFormattedOutputText);
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun(withOutMarkDown),
              ],
            }),
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, 'report.docx');
  };


  return (
    // <div className="md:bg-[#4F5AF5]">
    //   <div className="p-3 md:rounded-l-[65px] bg-[#ECF2FB] md:h-screen flex flex-col items-center overflow-hidden">
    //     <div>
    //       <Header title="Report" setIsAuth={setIsAuth} />
    //     </div>
    <SectionCard
      title={t('reportAndSummary')}
      innerClassName="md:w-full w-[84vw] xl:h-full h-[160vh]"
      children={
        <div className="md:flex justify-between gap-5">
          <div className="md:w-1/2 px-5 flex flex-col justify-start">
            <h1 className="text-sm font-bold mt-5">
              {t('uploadReport')}
            </h1>
            <div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                accept=".pdf"
                onChange={handleFileUpload}
              />
              <label
                htmlFor="fileInput"
                className="w-full flex gap-2 items-center justify-center text-sm rounded-full py-2 px-10 text-white bg-[#4F5AF5] font-bold my-3 cursor-pointer"
              >
                <GoUpload />
                {
                  isReportUploaded ? "Uploaded" : t('uploadReport')
                }
              </label>
            </div>
            <div className="flex justify-between">
              <FormDropdown
                id="length"
                label={t('length')}
                value={length}
                onChange={(event) => setLength(event.target.value)}
                options={lengthopt}
                placeholder={t('selectLength')}
                required={true}
              />
              <SearchAbleDropDown
                id="language"
                label={t('language')}
                value={targetLanguage}
                onChange={(event) => setTargetLanguage(event.target.value)}
                options={languages}
                placeholder={t('selectLanguage')}
                required={true}
                width="full"
                isMulti={false}
                className="w-[46%]"
              />
            </div>

            <div className="flex mt-2">
              <h1 className="text-sm font-bold">
                {t('isThisReportFinancial')}
              </h1>
              <div className="w-full h-[2px]">
                <input type="checkbox"
                  id="financial"
                  name="financial"
                  value={isFinancial}
                  onClick={
                    () => setIsFinancial(!isFinancial)
                  }
                />
              </div>
            </div>

            <div className="h-[28vh]"></div>
            <div className="flex flex-col items-end">
              <div className="w-full h-[2px] my-4 bg-[#E2E4FB]"></div>
              <button className={`w-full flex gap-2 items-center justify-center text-sm rounded-full py-2 px-10 text-white bg-[#FF8203] font-bold my-3 hover:shadow-xl transition duration:300 ${isReportComplete
                ? ""
                : "bg-neutral-400 pointer-events-none"
                }`}
                onClick={handleReport}
                disabled={!isReportComplete}
              >
                <FiRefreshCw className={loading && 'animate-spin'} />
                {t('generate')}
              </button>
            </div>
          </div>
          <div className="w-[2px] mx-5 bg-[#E2E4FB]"></div>
          {/* translation results  */}
          <div className="md:w-1/2 h-full px-3">
            <h1 className="text-sm font-bold mt-5">
              {t('generatedReport')}
            </h1>
            <div className="flex justify-center items-center">
              <SectionCard
                iconpath={"assets/ready.png"}
                title={
                  loading ? (t('generatingReport')) : (t('report'))
                }
                className="text-center bg-[#6BCEB9] flex items-center justify-center gap-2"
                innerClassName="h-[46vh] md:w-[35vw] w-[76vw]"
                children={
                  <div className="h-full">
                    <div className="text-sm pr-7 pl-7 text-justify h-[85%] overflow-y-auto" id="topdf">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: customMarkdownToHTML(outputText),
                        }}
                        className="pt-5"
                        id="formattedReport"
                        ref={targetRef}
                      />
                    </div>
                    <div className="flex items-end justify-end">
                      <button onClick={handleCopy} className="hover:bg-black hover:bg-opacity-5 p-3 rounded-full">
                        <MdContentCopy size={22} />
                      </button>
                    </div>
                  </div>
                }
              />
            </div>
            <div className="w-full h-[2px] my-5 bg-[#E2E4FB]"></div>
            <div className="w-full md:flex justify-end items-end gap-3 px-3">
              <button className="px-5 w-full flex justify-between items-center py-2.5 border border-[#E2E4FB] rounded-full" onClick={handleDownloadPDF}>
                <img src="assets/download.png" width={25} alt="" />
                {t('downloadAs')} <img src="assets/pdf.png" width={25} alt="" />
              </button>
              <button className="px-8 w-full flex justify-between items-center py-2.5 border border-[#E2E4FB] rounded-full" onClick={handleDownloadWord}>
                <img src="assets/download.png" width={25} alt="" />
                {t('downloadAs')} <img src="assets/word.png" width={25} alt="" />
              </button>
            </div>
          </div>
        </div>
      }
    />
    //   </div>
    // </div>
  );
};

export default Report;
