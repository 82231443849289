export const jsonToCSV = (jsonData) => {
  const csvRows = [];
  // Get the headers
  const headers = Object.keys(jsonData[0]);
  csvRows.push(headers.join(","));

  // Loop over the rows
  for (const row of jsonData) {
    const values = headers.map((header) => {
      const escaped = ("" + row[header]).replace(/"/g, '\\"'); // Handle quotes
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
};

export const downloadCSV = (data, fileName = "data.csv") => {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(link.href); // Clean up after download
};

export const snakeCaseToSentenceCase = (input) => {
  return input
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const customMarkdownToHTML = (markdown) => {
  markdown = markdown.replace(/^# (.*$)/gim, "<h1>$1</h1>");
  markdown = markdown.replace(/^## (.*$)/gim, "<h2>$1</h2>");
  markdown = markdown.replace(/^### (.*$)/gim, "<h3>$1</h3>");
  markdown = markdown.replace(/^#### (.*$)/gim, "<h4>$1</h4>");
  markdown = markdown.replace(/^##### (.*$)/gim, "<h5>$1</h5>");
  markdown = markdown.replace(/^###### (.*$)/gim, "<h6>$1</h6>");

  // Convert bold text
  markdown = markdown.replace(/\*\*(.*)\*\*/gim, "<strong>$1</strong>");
  markdown = markdown.replace(/__(.*)__/gim, "<strong>$1</strong>");

  // Convert italic text
  markdown = markdown.replace(/\*(.*)\*/gim, "<em>$1</em>");
  markdown = markdown.replace(/_(.*)_/gim, "<em>$1</em>");

  // Convert unordered lists
  markdown = markdown.replace(/^\s*\n\* (.*)/gim, "<ul>\n<li>$1</li>\n</ul>");
  // Nested <ul> tags are simplified for brevity. More complex list handling will require additional logic.

  // Replace newlines with <br /> tags
  markdown = markdown.replace(/\n/g, "<br />");

  // Remove unnecessary multiple <br /> tags added by lists and headers
  markdown = markdown.replace(/(<br \/>)+<\/ul>/g, "</ul>");
  markdown = markdown.replace(/<h([1-6])><br \/>/g, "<h$1>");

  return markdown.trim(); // Trim the final string to avoid unwanted spaces at the start and end.
};

export function removeMarkdownFormatting(markdown) {
  // Remove headers
  let text = markdown.replace(/^#{1,6}\s+(.+)/gm, "$1");

  // Remove bold and italic
  text = text.replace(/(\*\*|__)(.*?)\1/g, "$2"); // bold
  text = text.replace(/(\*|_)(.*?)\1/g, "$2"); // italic
  text = text.replace(/`(.+?)`/g, "$1"); // inline code

  // Remove strikethrough
  text = text.replace(/~~(.*?)~~/g, "$1");

  // Remove links but keep link text
  text = text.replace(/\[([^\]]+)\]\([^\)]+\)/g, "$1");

  // Remove images but keep alt text
  text = text.replace(/!\[([^\]]*)\]\([^\)]+\)/g, "$1");

  // Remove blockquotes
  text = text.replace(/^>\s+(.+)/gm, "$1");

  // Remove unordered list items
  text = text.replace(/^\s*[-+*]\s+/gm, "");

  // Remove ordered list items
  text = text.replace(/^\s*\d+\.\s+/gm, "");

  // Remove horizontal rules
  text = text.replace(/^-{3,}$/gm, "");

  // Remove any remaining markdown
  text = text.replace(/[_*`~]/g, "");

  return text;
}
