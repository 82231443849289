import React, { useState } from "react";
import { FormInput } from "../../Components/items";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signUpService } from "../../service/auth";
import { useTranslation } from 'react-i18next';



const SignUp = ({ setIsAuth }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSignUp = async (event) => {
    if (name === "" || email === "" || password === "") {
      toast.error(t("pleasefillallthefields"));
    };
    event.preventDefault();
    console.log("name: ", name);
    try {
      const data = await signUpService({
        name,
        email,
        password,
      });
      if (data) {
        navigate("/");
      } else {
        // toast.error("Something went wrong. Please try again");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again");
    }
  }

  return (
    <div className="md:flex h-screen">
      <div className=" hidden bg-[#4F5AF5] md:w-1/2 h-full md:flex items-center justify-center">
        <img src="assets/logoSignup.png" alt="AI Development" width={480} />
      </div>
      <div className="md:bg-[#4F5AF5] md:w-1/2  h-screen flex flex-col justify-center items-center">
        <div className="bg-white md:border md:rounded-l-[40px] md:w-full h-screen flex flex-col justify-center items-center ">
          <div className="text-left space-y-12">
            <h1 className="text-4xl font-semibold">{t("Helló!")}</h1>
            <form onSubmit={handleSignUp}>
              <FormInput id="name" placeholder={t('enterName')} value={name} onChange={(e) => setName(e.target.value)} required={true} />
              <FormInput
                id="email"
                type="email"
                value={email}
                placeholder={t('enterEmail')}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
              />
              <FormInput
                type="password"
                id="password"
                value={password}
                placeholder={t('enterPassword')}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
              />
              <button
                type="submit"
                className="bg-[#4F5AF5] text-white text-center py-2.5 rounded-full w-full font-bold mt-5"
                onClick={handleSignUp}
              >
                {t('signUp')}
              </button>
            </form>
            <p className="text-sm">
              {t("alreadyhaveanaccount?")}
              <Link to="/" className="text-violet-600 font-medium">
                {t('logIn')}
              </Link>
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SignUp;
