import React, { useState, useEffect } from "react";
import Header from "./Header";
import SectionCard from "../../Components/sectioncard";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { generateTellMeService } from "../../service/util";
import { DashboardInput, FormDropdown } from "../../Components/items";
import { FiRefreshCw } from "react-icons/fi";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import { customMarkdownToHTML } from "../../utils/csv.utils";

const lengthopt = [
  { value: "100", label: "100" },
  { value: "250", label: "250" },
  { value: "500", label: "500" },
  { value: "700", label: "700" },
];

const TellMe = ({ setIsAuth }) => {
  const { t } = useTranslation(); // Access translation function

  const [inputText, setInputText] = useState("");
  const [answerLength, setAnswerLength] = useState("");
  const [outputText, setOutputText] = useState("");
  const [isInputComplete, setIsInputComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsInputComplete(inputText.trim() !== "" && answerLength !== "");
  }, [inputText, answerLength]);

  // const formattedOutput = outputText.replace(/\n/g, "<br>");
  const formattedOutput = customMarkdownToHTML(outputText);

  const handleTellMeCall = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      if (inputText === "") {
        console.log("Please enter some text to analyze.");
        return;
      }

      const data = {
        query: inputText,
        answerLength,
      };

      const response = await generateTellMeService(data);
      if (response) {
        console.log("response: ", response);
        setOutputText(response?.result ?? "");
        toast.success('Ready!')
      } else {
        console.log(
          "An error occurred while analyzing the text. Please try again."
        );
        toast.error('An error occured. Please try again.')
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error)
    } finally {
      setLoading(false);
    }
  };
  const handleCopy = () => {
    const emailContentDiv = document.getElementById("formattedOutput");
    const range = document.createRange();
    range.selectNode(emailContentDiv);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    toast.success("Copied to clipboard");
  };

  return (
    // <div className="md:bg-[#4F5AF5]">
    //   <div className="p-3 md:rounded-l-[65px] bg-[#ECF2FB] md:h-screen h-full md:w-full w-screen flex flex-col items-center overflow-hidden">
    //     <div>
    //       <Header title="Tell Me" setIsAuth={setIsAuth} />
    //     </div>
    <SectionCard
      title={t('operify-tellMe')}
      innerClassName='md:h-[115vh]'
      children={
        <div className="">
          <div className="px-5 py-3">
            <DashboardInput
              id="about"
              label={t('tellMeAbout')}
              value={inputText}
              onChange={(e) => {
                setInputText(e.target.value);
              }}
              placeholder={t('typeHere')}
              required={true}
            />

            <FormDropdown
              id="emailType"
              label={t('length')}
              value={answerLength}
              onChange={(e) => {
                setAnswerLength(e.target.value);
              }}
              options={lengthopt}
              placeholder={t('selectLength')}
              required={true}
            />
            <div className="flex justify-center cursor:pointer">
              <button
                className={`rounded-full flex items-center gap-2 hover:shadow-xl transition duration:300 ${isInputComplete
                  ? "bg-orange-500"
                  : "bg-neutral-400 pointer-events-none"
                  } py-2 px-8 text-white text-sm font-semibold`}
                onClick={handleTellMeCall}
                disabled={!isInputComplete}
              >
                <FiRefreshCw className={loading && 'animate-spin'} />
                {t('generate')}
              </button>
            </div>
          </div>
          <div className="w-full h-[0.5px] bg-neutral-300"></div>

          {/* Generated Email */}
          <div className="p-4 text-sm">
            <div className="font-bold">
              {t('hereIsWhatIFound')}:</div>
            <div className="flex justify-center items-center">
              <SectionCard
                iconpath={"assets/ready.png"}
                title={outputText === "" ? t('output') : t("ready")}
                className="text-center bg-[#6BCEB9] flex items-center justify-center gap-2"
                innerClassName='md:h-[35vh] h-[40vh] md:w-[75vw] w-[80vw]'
                children={
                  <div className="h-full">
                    <div className="text-sm pr-7 pl-7 text-justify h-[83%] w-full overflow-x-auto overflow-y-auto">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formattedOutput,
                        }}
                        className="pt-5"
                        id="formattedOutput"
                      />
                    </div>
                    <div className="flex items-end justify-end">
                      <button onClick={handleCopy} className="hover:bg-black hover:bg-opacity-5 p-3 rounded-full">
                        <MdContentCopy size={22} />
                      </button>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      }
    />
    //   </div>
    // </div>
  );
};

export default TellMe;
