import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import { toast } from "react-toastify";
import { fetchUserDetailsService, updateProfileService } from "../../service/user";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import HomeModal from "../../Components/homeModal";
import api from "../../config/api";

const items = [
  {
    title: `textGenerated`,
    count: 10808,
  },
  {
    title: "reportGenerated",
    count: 40,
  },
  {
    title: "contentGenerated",
    count: 10696,
  },
  {
    title: "hoursSaved",
    count: 36,
  },
];

const Home = ({ setIsAuth }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [modalTitle, setModalTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuth(false);
        localStorage.removeItem("token");
        toast.error("Session expired. Please login again.");
        return;
      }
      const data = await fetchUserDetailsService();
      if (data) {
        setUserData(data);
        setModalData(data.statsItem);
      }
    } catch (error) {
      console.error("Failed to fetch profile data:", error);
    } finally {
      console.log("Profile data fetched successfully");
    }
  };



  useEffect(() => {
    fetchProfile();
  }, []);

  const openModal = (title, graphValue) => {
    setModalTitle(title);
    setGraphData(graphValue);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const fileInputRef = useRef(null);
  const handleImageUpload = async (event) => {
    const { REACT_APP_CLOUD_NAME, REACT_APP_UPLOAD_PRESET } = api.cloudinary;
    if (!REACT_APP_CLOUD_NAME || !REACT_APP_UPLOAD_PRESET) {
      console.error("Cloudinary environment variables not found");
      toast.error("Error uploading image.");
      return;
    }

    const files = event.target.files;
    if (files.length === 0) {
      toast.error("No file selected.");
      return;
    }

    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", REACT_APP_UPLOAD_PRESET);

    try {
      const API_URL = `https://api.cloudinary.com/v1_1/${REACT_APP_CLOUD_NAME}/image/upload`;
      const response = await fetch(API_URL, {
        method: "POST",
        body: data
      });
      const file = await response.json();

      if (file.secure_url) {
        toast.success("Image uploaded successfully!");
        Object.assign(userData, {
          logo: file.secure_url,

        })
        await updateProfile(userData);
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image.");
    }
  };
  const updateProfile = async (data) => {
    const response = await updateProfileService(data);
    if (response) {
      toast.success("Company Logo updated successfully");
      await fetchProfile();
    } else {
      toast.error("Failed to update profile");
    }
  }
  return (
    // <div className="md:bg-[#4F5AF5]">
    //   <div className="p-3 md:rounded-l-[65px] bg-[#ECF2FB] md:h-screen md:w-full w-screen flex flex-col overflow-hidden">

    <div className="flex flex-col justify-between items-center space-y-6 h-full">
      <div className="md:flex justify-between w-full px-10 md:space-y-0 space-y-5">
        <div className="bg-[#FDFFFF] md:w-[40%] p-3 text-white shadow-xl rounded-3xl shadow-[#666CFF99]">
          <div className="relative bg-gradient-to-r from-[#6874FF] to-[#404DE0] shadow-lg rounded-3xl h-full p-8 flex flex-col justify-center space-y-2">
            <h1 className="text-2xl md:text-3xl font-bold">
              {t('welcome', { name: userData.name })}
            </h1>
            <p className="text-lg md:text-xl font-bold">{t('companyName')}</p>
            <p className="text-sm md:text-base font-regular w-full md:w-1/2 text-left">
              {t('slogan')}
            </p>
            <img
              src={"assets/maskedCircle.png"}
              alt="mask"
              className="absolute bottom-0 right-0"
              width={150}
              style={{ opacity: 0.4 }}
            />
          </div>
        </div>
        <div className="relative bg-[#FDFFFF] xl:w-[59%] xl:h-[35vh] p-3 text-white shadow-xl rounded-3xl shadow-[#666CFF99]">
          <div className="relative bg-gradient-to-r from-[#6874FF] to-[#404DE0] shadow-lg grid grid-cols-1 md:grid-cols-2 gap-5 rounded-3xl h-full py-4 px-6 ">
            {modalData?.map(
              (item, index) => {
                console.log(item);
                return (
                  <div
                    key={index}
                    className="bg-white bg-opacity-20 text-lg rounded-3xl p-3 flex flex-col justify-between z-10"
                  >
                    <h1 className="text-lg">{t(item.title)}</h1>
                    <div className="flex justify-between items-center">
                      <p className="font-bold">{item.count}</p>
                      <button
                        onClick={() => openModal(item.title, item.value)}
                        className="flex gap-2 items-center rounded-full text-sm border-2 border-white px-4 py-1"
                      >
                        {t('view')}
                        <FaExternalLinkAlt size={10} />
                      </button>
                    </div>
                  </div>
                )
              })}
            {showModal && <HomeModal title={modalTitle}
              graphData={graphData}
              onClose={closeModal} />}
          </div>
          <img
            src={"assets/maskedCircle.png"}
            alt="mask"
            className="absolute bottom-0 right-0"
            width={150}
            style={{ opacity: 0.4 }}
          />
        </div>
      </div>

      <div className="flex justify-center pb-6">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none", cursor: "pointer" }}
          id="image-upload"
        />
        <label htmlFor="image-upload">
          <div className="relative w-[78vw] h-[30vh] overflow-hidden p-1 ">
            {userData.company?.logo ? (
              <img
                src={userData.company.logo ?? 'assets/homelogo.png'}
                alt="Uploaded"
                className="w-full h-full object-cover cursor-pointer"
              />
            ) : (
              <div className=" bg-[#F3EFF7] p-5 w-full h-full"></div>
            )}
          </div>
        </label>
      </div>
    </div>
    //   </div>
    // </div>
  );
};

export default Home;
