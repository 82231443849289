import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { fetchUserDetailsService } from '../service/user';

const Credit = ({ onClose }) => {
  const { t } = useTranslation(); // Access translation function

  const [creditData, setCreditData] = useState([]);

  const fetchProfile = async () => {
    const data = await fetchUserDetailsService();
    if (data) {
      setCreditData(data);
    }
  }

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className=" shadow-[#666CFF99] text-white bg-gradient-to-r from-[#6874FF] to-[#404DE0] rounded-3xl p-6 md:w-1/3 relative sm:w-2/3">
        <div className='flex justify-between items-center mb-4'>
          <h2 className="text-lg font-semibold">{t('credits')}</h2>
          <button onClick={onClose} className="px-4">X</button>
        </div>

        <div className='rounded-2xl p-3 text-xl flex flex-col gap-3'>
          <div className='flex justify-between'><p>{t('emailGeneration')}:</p> <p className='flex items-center justify-center w-[6vw]'>{creditData?.availableCredits?.email_generation === -1 ? t('unlimited') : creditData?.availableCredits?.email_generation?creditData?.availableCredits?.email_generation:0}</p></div>
          <div className='flex justify-between'><p>{t('translation')}:</p> <p className='flex items-center justify-center w-[6vw]'>{creditData?.availableCredits?.translate === -1 ? t('unlimited') : creditData?.availableCredits?.translate?creditData?.availableCredits?.translate:0}</p></div>
          <div className='flex justify-between'><p>{t('reportGeneration')}:</p> <p className='flex items-center justify-center w-[6vw]'>{creditData?.availableCredits?.report === -1 ? t('unlimited') : creditData?.availableCredits?.report?creditData?.availableCredits?.report:0}</p></div>
          <div className='flex justify-between'><p>{t('socialMedia')}:</p> <p className='flex items-center justify-center w-[6vw]'>{creditData?.availableCredits?.social_media === -1 ? t('unlimited') : creditData?.availableCredits?.social_media?creditData?.availableCredits?.social_media:0}</p></div>
          <div className='flex justify-between'><p>{t('tellMe')}:</p> <p className='flex items-center justify-center w-[6vw]'>{creditData?.availableCredits?.tell_me === -1 ? t('unlimited') : creditData?.availableCredits?.tell_me?creditData?.availableCredits?.tell_me:0}</p></div>
          <img
            src={"assets/maskedCircle.png"}
            alt="mask"
            className="absolute bottom-0 right-0"
            width={150}
            style={{ opacity: 0.4 }}
          />
        </div>
      </div>
    </div>
  );
};

export default Credit;
