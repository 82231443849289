import React, { useState, useEffect } from "react";
import Header from "./Header";
import SectionCard from "../../Components/sectioncard";
import { FormDropdown, SearchAbleDropDown, MultiFormDropDown } from "../../Components/items";
import { FiRefreshCw } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { generateSocialMediaService } from "../../service/util";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { toast } from "react-toastify";
import { fetchUserDetailsService } from "../../service/user";

const contentopt = [
  { value: "blogs", label: "Blog Articles" },
  { value: "videos", label: "Videos" },
  { value: "infographics", label: "Infographics" },
];

const data = [
  {
    sno: 1,
    date: "2024-04-11",
    selectedPlatform: "Instagram",
    postContent: "Lorem ipsum...",
    caption: "Caption 1",
    pictureIdea: "Idea 1",
    time: "12:00 PM",
  },
  {
    sno: 2,
    date: "2024-04-11",
    selectedPlatform: "Instagram",
    postContent: "Lorem ipsum...",
    caption: "Caption 1",
    pictureIdea: "Idea 1",
    time: "12:00 PM",
  },
  {
    sno: 3,
    date: "2024-04-11",
    selectedPlatform: "Instagram",
    postContent: "Lorem ipsum...",
    caption: "Caption 1",
    pictureIdea: "Idea 1",
    time: "12:00 PM",
  },
];

const SocialMedia = ({ setIsAuth }) => {
  const [form, setForm] = useState({
    selectedPlatform: "",
    fromdate: "",
    enddate: "",
    content: "",
    type: "",
    themes: "",
    quantity: "",
    frequency: "",
  });
  const [content, setContent] = useState([{}])
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const { t } = useTranslation(); // Access translation function

  let typeopt = [
    { value: "blogs", label: "Blog Articles" },
    { value: "videos", label: "Videos" },
    { value: "infographics", label: "Infographics" },
    { value: "pictures", label: "Pictures" },
  ];
  let themesopt = [
    { value: "brand", label: "brand" },
    { value: "awareness", label: "awareness" },
    { value: "lead", label: "lead generation" },
    { value: "sales", label: "sales" },
  ];
  let quantityopt = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
  ];
  let freqopt = [
    { value: "monthly", label: "monthly" },
    { value: "daily", label: "daily" },
    { value: "weekly", label: "weekly" },
  ];


  const lang = localStorage.getItem("lang");

  if (lang === 'Hungarian') {
    typeopt = [
      { value: "blogs", label: "Blog cikkek" },
      { value: "videos", label: "Videók" },
      { value: "infographics", label: "Infografikák" },
      { value: "pictures", label: "Képek" }
    ];
    themesopt = [
      { value: "brand", label: "márka" },
      { value: "awareness", label: "tudatosság" },
      { value: "lead", label: "vezeték generálás" },
      { value: "sales", label: "értékesítés" },
    ];
    quantityopt = [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
    ];
    freqopt = [
      { value: "monthly", label: "havi" },
      { value: "daily", label: "naponta" },
      { value: "weekly", label: "hetente" },
    ];
  }

  const fetchProfile = async () => {
    try {
      const data = await fetchUserDetailsService();
      setContent(data.products);
    } catch (error) {
      console.error('Failed to fetch profile data:', error);
    }
  }
  useEffect(() => {
    fetchProfile();
  }, []);
  const handleSocialMediaSelect = (platform) => {
    // Check if the platform is already selected
    const platformIndex = form.selectedPlatform.indexOf(platform);
    if (platformIndex !== -1) {
      // If already selected, remove it from the selected platforms
      const updatedSelectedPlatform = form.selectedPlatform
        .split(",")
        .filter((item) => item !== platform)
        .join(",");
      setForm((prevForm) => ({
        ...prevForm,
        selectedPlatform: updatedSelectedPlatform,
      }));
    } else {
      // If not selected, add it to the selected platforms
      const updatedSelectedPlatform =
        form.selectedPlatform.length > 0
          ? `${form.selectedPlatform},${platform}`
          : platform;
      setForm((prevForm) => ({
        ...prevForm,
        selectedPlatform: updatedSelectedPlatform,
      }));
    }
  };
  const isSocialMediaSelected = (platform) => {
    return form.selectedPlatform.split(",").includes(platform);
  };
  useEffect(() => {
    setIsFormComplete(
      Object.entries(form).every(([key, value]) =>
        Array.isArray(value) ? value.length > 0 : value !== ""
      )
    );
  }, [form]);

  const handleContent = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const response = await generateSocialMediaService(form);
      if (response.success) {
        console.log("response: ", response);
        setOutputData(response?.result ?? []);
        toast.success('Content Generated !')
      } else {
        console.log(
          "An error occurred while analyzing the content. Please try again."
        );
        let error = response?.message ?? "An error occurred while analyzing the content. Please try again.";
        toast.error(error)
        setContent([]);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const csvData = [
    [
      "Sno",
      "Date",
      "Platform",
      "Post Content",
      "Caption",
      "Picture Idea",
      "Time",
    ],
    ...outputData.map(
      ({
        serial_number,
        date,
        platform,
        post_content,
        caption,
        picture_idea,
        upload_time,
      }) => [
          serial_number,
          date,
          platform,
          post_content,
          caption,
          picture_idea,
          upload_time,
        ]
    ),
  ];

  return (
    // <div className="md:bg-[#4F5AF5]">
    //   <div className="p-3 md:rounded-l-[65px] bg-[#ECF2FB] md:h-screen overflow-hidden flex flex-col items-center">
    //     <div>
    //       <Header title="Social Media" setIsAuth={setIsAuth} />
    //     </div>
    <SectionCard
      title={t('socialMedia')}
      innerClassName={''}
      children={
        <div className="px-5 py-5 w-full overflow-y-scroll xl:h-[75vh]">
          <div className="flex justify-evenly w-full">
            <div className="text-sm font-bold w-[20%]">
              <h1 className="pb-[7vh]">
                {t('selectPlatform')}
              </h1>
              <h1 className="pb-[10.5vh]">
                {t('content')}
              </h1>
              <h1>
                {t('contentCalendar')}
              </h1>
            </div>
            <div className="w-0.5 h-[65vh] mr-10 bg-[#E2E4FB]"></div>

            <div className="space-y-5 w-[99%] h-full">
              <div className="md:flex gap-2 text-white text-sm md:space-y-0 space-y-3 ">
                <button
                  className={`py-1.5 flex gap-2 items-center px-5 rounded-full ${isSocialMediaSelected("Facebook")
                    ? "bg-[#4F5AF5] text-white"
                    : "bg-[#9296C3]"
                    }`}
                  onClick={() => handleSocialMediaSelect("Facebook")}
                >
                  <img src="assets/fb.png" width={25} alt="" />
                  Facebook
                </button>
                <button
                  className={`py-1.5 flex gap-2 items-center px-5 rounded-full ${isSocialMediaSelected("Instagram")
                    ? "bg-[#4F5AF5] text-white"
                    : "bg-[#9296C3]"
                    }`}
                  onClick={() => handleSocialMediaSelect("Instagram")}
                >
                  <img src="assets/ig.png" width={25} alt="" />
                  Instagram
                </button>
                <button
                  className={`py-1.5 flex gap-2 items-center px-5 rounded-full ${isSocialMediaSelected("Twitter")
                    ? "bg-[#4F5AF5] text-white"
                    : "bg-[#9296C3]"
                    }`}
                  onClick={() => handleSocialMediaSelect("Twitter")}
                >
                  <img src="assets/twitter.png" width={25} alt="" />
                  Twitter
                </button>
                <button
                  className={`py-1.5 flex gap-2 items-center px-5 rounded-full ${isSocialMediaSelected("LinkedIn")
                    ? "bg-[#4F5AF5] text-white"
                    : "bg-[#9296C3]"
                    }`}
                  onClick={() => handleSocialMediaSelect("LinkedIn")}
                >
                  <img src="assets/linkedin.png" width={25} alt="" />
                  LinkedIn
                </button>
              </div>
              <div className="w-full h-[2px] bg-[#E2E4FB]"></div>
              <div>
                <MultiFormDropDown
                  label={t('serviceProducts')}
                  value={form.content}
                  options={content}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    console.log('selectedValue: ', selectedValue);
                    setForm((prevForm) => ({
                      ...prevForm,
                      content: selectedValue?.join(", ")
                    }));
                  }}
                  width="13vw"
                />
              </div>
              <div className="w-full h-[2px] bg-[#E2E4FB]"></div>
              <div className="grid md:grid-cols-4 grid-cols-1">
                <div className="flex flex-col">
                  <label htmlFor="fromdate" className="text-sm font-bold">
                    {t('startDate')}
                  </label>
                  <DatePicker
                    id="fromdate"
                    placeholderText="DD/MM/YYYY"
                    selected={form.fromdate}
                    className="rounded-full border py-1.5 text-sm italic px-2"
                    onChange={(date) =>
                      setForm((prevForm) => ({
                        ...prevForm,
                        fromdate: date,
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="enddate" className="text-sm font-bold">
                    {t('endDate')}
                  </label>
                  <DatePicker
                    id="enddate"
                    placeholderText="DD/MM/YYYY"
                    selected={form.enddate}
                    className="rounded-full border py-1.5 text-sm italic px-2"
                    onChange={(date) =>
                      setForm((prevForm) => ({
                        ...prevForm,
                        enddate: date,
                      }))
                    }
                  />
                </div>
                <div
                  className="mr-2"
                >
                  <MultiFormDropDown
                    label={t('type')}
                    value={form.type}
                    options={typeopt}
                    placeholder={t('select')}
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      setForm((prevForm) => ({
                        ...prevForm,
                        type: selectedValue.join(", ")
                      }));
                    }}
                    width="13vw"
                  />
                </div>
                <div
                  className="ml-2"
                >
                  <MultiFormDropDown
                    label={t('themes')}
                    value={form.themes}
                    placeholder={t('select')}
                    options={themesopt}
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      setForm((prevForm) => ({
                        ...prevForm,
                        themes: selectedValue.join(", ")
                      }));
                    }}
                    width="13vw"
                  />
                </div>
                <FormDropdown
                  label={t('quantity')}
                  value={form.quantity}
                  options={quantityopt}
                  placeholder={t('select')}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    setForm((prevForm) => ({
                      ...prevForm,
                      quantity: selectedValue,
                    }));
                  }}
                  width="13vw"
                  className="pr-5"
                />
                <FormDropdown
                  label={t('frequency')}
                  value={form.frequency}
                  options={freqopt}
                  placeholder={t('select')}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    setForm((prevForm) => ({
                      ...prevForm,
                      frequency: selectedValue,
                    }));
                  }}
                  width="13vw"
                />
                <div className="cursor:pointer flex items-center ml-3 mt-2">
                  <button
                    className={`rounded-full flex items-center gap-2 ${isFormComplete
                      ? "bg-orange-500"
                      : "bg-neutral-400 pointer-events-none"
                      } py-3 px-8 text-white text-sm font-semibold hover:shadow-xl transition duration:300`}
                    onClick={handleContent}
                    disabled={!isFormComplete}

                  >
                    <FiRefreshCw className={loading && "animate-spin"} />
                    {t('generate')}
                  </button >
                </div >
              </div >
              <div className="w-full h-[2px] bg-[#E2E4FB]"></div>

              {/* generated content  */}
              <div className="w-full overflow-y-scroll">
                <table className="w-full text-left border-collapse border-b border-gray-300 text-sm">
                  <thead>
                    <tr className="bg-gray-200 ">
                      <th className="p-3">
                        {t('no.')}
                      </th>
                      <th className="p-3">
                        {t('date')}
                      </th>
                      <th className="p-3">
                        {t('platform')}
                      </th>
                      <th className="p-3">
                        {t('postContent')}
                      </th>
                      <th className="p-3">
                        {t('caption')}
                      </th>
                      <th className="p-3">
                        {t('pictureIdea')}
                      </th>
                      <th className="p-3">
                        {t('time')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {outputData.map((item, index) => (
                      <tr key={index}>
                        <td className="p-3">{item.serial_number}</td>
                        <td className="p-3">{item.date}</td>
                        <td className="p-3">{item.platform}</td>
                        <td className="p-3">{item.post_content}</td>
                        <td className="p-3">{item.caption}</td>
                        <td className="p-3">{item.picture_idea}</td>
                        <td className="p-3">{item.upload_time}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end w-full pb-5">
                <CSVLink
                  filename="my-content.csv"
                  data={csvData}
                  className="px-3 w-1/4 flex justify-between items-center py-2.5 border border-[#E2E4FB] rounded-full"
                >
                  <img src="assets/download.png" width={25} alt="" />
                  {t('downloadAs')}
                  <img src="assets/xcel.png" width={25} alt="" />
                </CSVLink>
              </div>
            </div >
          </div >
        </div >
      }
    />
    //   </div >
    // </div >
  );
};

export default SocialMedia;
