import React, { useEffect, useState } from "react";
import { fetchPlansService } from "../service/user";
import { toast } from "react-toastify";
import { checkoutService } from "../service/checkout";

// const plans = [
//   {
//     name: "Basic Plan",
//     amount: "$10",
//     includedFunctions: ["Function 1"],
//     translate: true,
//     report: true,
//     socialMedia: false,
//     emailGeneration: false,
//     tellMe: false,
//   },
//   {
//     name: "Standard Plan",
//     amount: "$20",
//     includedFunctions: ["Function 1"],
//     translate: true,
//     report: true,
//     socialMedia: true,
//     emailGeneration: false,
//     tellMe: false,
//   },
//   {
//     name: "Premium Plan",
//     amount: "$30",
//     includedFunctions: ["Function 1"],
//     translate: true,
//     report: true,
//     socialMedia: true,
//     emailGeneration: true,
//     tellMe: false,
//   },
//   {
//     name: "Premium Plan",
//     amount: "$30",
//     includedFunctions: ["Function 1"],
//     translate: true,
//     report: true,
//     socialMedia: true,
//     emailGeneration: true,
//     tellMe: false,
//   },

// ];

const Plans = ({ isOpen, onClose, currentPlanId }) => {

  const [plans, setPlans] = useState([]);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);


  const fetchPlans = async () => {
    const data = await fetchPlansService();
    if (data) {
      const sortedData = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setPlans(sortedData);
    }
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleCheckoutService = async (stripePriceId) => {
    console.log('stripePriceId: ', stripePriceId);
    setIsCheckoutLoading(true);
    if (!stripePriceId) {
      toast.error('Please select a plan');
      setIsCheckoutLoading(false);
      return;
    }
    const data = await checkoutService({ stripePriceId });
    if (data) {
      console.log('data: ', data);
      const { checkout_url } = data;
      toast.success('Redirecting to checkout page');
      // open stripe checkout page in same tab
      window.location.href = checkout_url;
      setIsCheckoutLoading(false);
    }
  }


  return (
    isOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-md p-6 md:w-3/4 lg:w-5/6 xl:w-2/3 md:h-[60%] lg:h-[70%] xl:h-[64%] h-[90%] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-bold">Plans</h2>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 overflow-x-auto">
            {
              plans?.length && plans.map((plan, index) => (
                <div key={plan._id} className="border p-4 rounded-lg">
                  <h3 className="text-lg font-bold">{plan.planName}</h3>
                  <p className="text-gray-600">{plan.amount}</p>
                  <p className="text-gray-600">Trial Period {plan?.trial_period_days ?? '0'} Days</p>
                  {/* <ul className="list-disc list-inside mt-2">
                    {
                      Object.keys(plan.functions).map((key, index) => (
                        <li key={index}>{plan.functions[key]}</li>
                      ))
                    }
                  </ul> */}
                  <div className="mt-4 flex flex-col justify-between h-64">
                    <p><strong>Translate:</strong> {plan?.functions?.translate === -1 ? 'Unlimited' : plan.functions.translate}</p>
                    <p><strong>Report:</strong> {plan?.functions?.report === -1 ? 'Unlimited' : plan.functions.report}</p>
                    <p><strong>Social Media:</strong> {plan?.functions?.social_media === -1 ? 'Unlimited' : plan.functions.social_media}</p>
                    <p><strong>Email Generation:</strong> {plan?.functions?.email_generation === -1 ? 'Unlimited' : plan.functions.email_generation}</p>
                    <p><strong>Tell Me:</strong> {plan?.functions?.tell_me === -1 ? 'Unlimited' : plan.functions.tell_me}</p>
                    <button disabled={isCheckoutLoading} className={`${plan?._id === currentPlanId ? "bg-green-600 disabled:bg-green-300" : "bg-blue-500"} disabled:bg-blue-300 text-white rounded-full my-6 px-3 py-1 w-full shadow`}
                      onClick={() => handleCheckoutService(plan.stripePriceId)}
                    >
                      {plan?._id === currentPlanId ? 'Current Plan ✅' : 'Select Plan'}
                    </button>

                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  );
};

export default Plans;
