import React, { useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { CiFilter } from "react-icons/ci";
import { useTranslation } from "react-i18next";

const SectionCard = ({
  iconpath,
  title,
  children,
  className,
  innerClassName,
  refresh,
  filter,
  onSelectFilter,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFilterSelect = (filter) => {
    onSelectFilter(filter);
    setIsDropdownOpen(false);
  };

  return (
    <div className={`bg-[#F7FAFE] rounded-2xl border xl:w-[78vw] h-[84%]`}>
      <div
        className={`w-full flex items-center p-3 rounded-t-2xl text-white ${className ? className : "bg-[#4F5AF5]"
          }`}
      >
        <img src={iconpath} width={25} alt="" />
        {t(title)}
        <div className="flex items-center ml-auto">
          {refresh && (
            <button className="hover:bg-black hover:bg-opacity-5 p-3 flex rounded-full items-center">
              <IoMdRefresh size={20} />
            </button>
          )}
          {filter && (
            <div className="relative hover:bg-black hover:bg-opacity-5 p-3 flex rounded-full items-center">
              <button onClick={toggleDropdown}>
                <CiFilter size={20} />
              </button>
              {isDropdownOpen && (
                <FilterDropdown
                  options={[
                    "all",
                    "socialMedia",
                    "emailGeneration",
                    "helpMe",
                  ]}
                  onSelect={handleFilterSelect}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className={` ${innerClassName}`}>{children}</div>
    </div>
  );
};

const FilterDropdown = ({ options, onSelect }) => {
  const camelCaseToSentenceCase = (input) => {
    const spaced = input.replace(/([A-Z])/g, " $1");
    const result =
      spaced.charAt(0).toUpperCase() + spaced.slice(1).toLowerCase();
    return result;
  };

  const { t } = useTranslation();

  return (
    <div className="absolute top-0 mt-10 right-0 w-60 text-sm bg-white rounded-md shadow-lg z-10">
      <ul>
        {options.map((option, index) => (
          <li
            key={index}
            className="px-4 py-2 cursor-pointer text-black hover:bg-gray-200"
            onClick={() => onSelect(option)}
          >
            {t(camelCaseToSentenceCase(option))}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SectionCard;
