const baseUrl = `https://server.operify.hu`;
// let baseUrl = `http://localhost:5000`;

const api = {
  auth: {
    login: `${baseUrl}/api/signin`,
    signup: `${baseUrl}/api/signup`,
  },
  util: {
    email: `${baseUrl}/api/email`,
    translate: `${baseUrl}/api/translate`,
    report: `${baseUrl}/api/report`,
    socialMedia: `${baseUrl}/api/socialMedia`,
    tellMe: `${baseUrl}/api/help`,
  },
  user: {
    details: `${baseUrl}/api/user`,
    plans: `${baseUrl}/api/plans`,
    history: `${baseUrl}/api/history`,
    update: `${baseUrl}/api/updateProfile`,
    password: `${baseUrl}/api/updatePassword`,
  },
  cloudinary: {
    upload: "https://api.cloudinary.com/v1_1/{{cloud_name}}/image/upload",
    REACT_APP_CLOUD_NAME: "duxr6edeo",
    REACT_CLOUDINARY_API_KEY: 741618948228439,
    REACT_APP_UPLOAD_PRESET: "giy3fgh7",
    REACT_CLOUDINARY_API_SECRET: "N9Df7GeluzNr4kfzagqYeDw7Gkk",
  },
  checkout: {
    stripe: `${baseUrl}/api/stripe/payment`,
  },
};

export default api;
