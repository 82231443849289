import React, { useState, useEffect } from "react";
import Header from "./Header";
import SectionCard from "../../Components/sectioncard";
import { DashboardInput, FormDropdown } from "../../Components/items";
import { MdContentCopy } from "react-icons/md";
import { FiRefreshCw } from "react-icons/fi";
import { toast } from "react-toastify";
import { generateEmailService } from "../../service/util";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { fetchUserDetailsService } from "../../service/user";



const Email = ({ setIsAuth }) => {
  const { t } = useTranslation(); // Access translation function

  let emailtype = [
    { value: "inside", label: "Inside Email" },
    { value: "close", label: "Close Partner Email" },
    { value: "normal", label: "Normal Partner Email" },
    { value: "unknown", label: "Unknown Partner Email" },
  ];

  let length = [
    { value: "short", label: "Short" },
    { value: "medium", label: "Medium" },
    { value: "long", label: "Long" },
    { value: "extralong", label: "Extra Long" },
  ];

  const lang = localStorage.getItem("lang");
  if (lang === "Hungarian") {
    emailtype = [
      { value: "inside", label: "Belső Email" },
      { value: "close", label: "Közeli Partner Email" },
      { value: "normal", label: "Normál Partner Email" },
      { value: "unknown", label: "Ismeretlen Partner Email" },
    ];

    length = [
      { value: "short", label: "Rövid" },
      { value: "medium", label: "Közepes" },
      { value: "long", label: "Hosszú" },
      { value: "extralong", label: "Extra Hosszú" },
    ];
  }




  const [form, setForm] = useState({
    typeOfEmail: "",
    emailTo: "",
    emailFrom: "",
    length: "",
    emailContent: "",
  });
  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuth(false);
        localStorage.removeItem("token");
        toast.error("Session expired. Please login again.");
        return;
      }
      const data = await fetchUserDetailsService();
      if (data) {
        console.log(data.email)
        setForm((form) => ({
          ...form,
          emailFrom: data.email,
        }));
      }
    } catch (error) {
      console.error("Failed to fetch profile data:", error);
    } finally {
      console.log("Profile data fetched successfully");
    }
  };




  useEffect(() => {
    fetchProfile();
  }, []);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [generatedEmail, setGeneratedEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsFormComplete(Object.values(form).every((value) => value !== ""));
  }, [form]);

  const formattedEmail = generatedEmail.replace(/\n/g, "<br>");

  const handleGenerate = async (e) => {
    try {
      setLoading(true);
      let payload = {
        typeOfEmail: form.typeOfEmail,
        emailTo: form.emailTo,
        emailFrom: form.emailFrom,
        length: form.length,
        emailContent: form.emailContent,
      };
      let { data } = await generateEmailService(payload);
      if (data?.generatedEmail.length > 1) {
        console.log("data: ", data);
        setGeneratedEmail(data?.generatedEmail);
        toast.success("Email Generated !");
      } else {
        const error = data?.message || "Error in generating email";
        toast.error(error);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    const emailContentDiv = document.getElementById("formattedEmail");
    const range = document.createRange();
    range.selectNode(emailContentDiv);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    toast.success("Copied to clipboard");
  };

  return (
    // <div className="bg-[#4F5AF5]">
    //   <div className="p-3 md:rounded-l-[65px] bg-[#ECF2FB] md:h-screen h-full md:w-full w-screen flex flex-col items-center overflow-hidden">
    //     <div>
    //       <Header title="Email Generation" setIsAuth={setIsAuth} />
    //     </div>
    <SectionCard
      title={t('generateEmail')}
      innerClassName='h-[115vh]'
      children={
        <div className="">
          <div className="px-5 py-3">
            <div className="md:flex justify-between">
              <FormDropdown
                id="emailType"
                label={t('writeAn')}
                value={form.typeOfEmail}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  setForm((prevForm) => ({
                    ...prevForm,
                    typeOfEmail: selectedValue,
                  }));
                }}
                options={emailtype}
                placeholder={t('selectType')}
                required={true}
              />

              <DashboardInput
                id="emailType"
                label={t('emailTo')}
                value={form.emailTo}
                onChange={(e) => {
                  setForm((form) => ({ ...form, emailTo: e.target.value }));
                }}
                placeholder={t('enterEmail')}
                required={true}
              />
              {/* <DashboardInput
                    id="from"
                    label="Email From"
                    value={form.emailFrom}
                    onChange={(e) => {
                      setForm((form) => ({
                        ...form,
                        emailFrom: e.target.value,
                      }));
                    }}
                    placeholder="Enter from"
                    required={true}
                  /> */}
              <FormDropdown
                id="length"
                label={t('inTheLengthOf')}
                value={form.length}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  setForm((prevForm) => ({
                    ...prevForm,
                    length: selectedValue,
                  }));
                }}
                options={length}
                placeholder={t('selectTheWordCount')}
                required={true}
              />
            </div>
            <DashboardInput
              id="about"
              label={t('about')}
              value={form.emailContent}
              onChange={(e) => {
                setForm((form) => ({
                  ...form,
                  emailContent: e.target.value,
                }));
              }}
              placeholder={t('typeHere')}
              required={true}
            />
            <div className="flex justify-center cursor:pointer">
              <button
                className={`rounded-full flex items-center gap-2 hover:shadow-xl transition duration:300 ${isFormComplete
                  ? "bg-orange-500"
                  : "bg-neutral-400 pointer-events-none"
                  } py-2 px-8 text-white text-sm font-semibold`}
                onClick={handleGenerate}
                disabled={!isFormComplete}
              >
                <FiRefreshCw className={loading && 'animate-spin'} />
                {t('generate')}
              </button>
            </div>
          </div>
          <div className="w-full h-[0.5px] bg-neutral-300"></div>

          {/* Generated Email */}
          <div className="p-4 text-sm">
            <div className="font-bold">
              <h1 className="text-lg">{t('generatedEmail')}</h1>
            </div>
            <div className="flex justify-center items-center">
              <SectionCard
                iconpath={'assets/ready.png'}
                title={generatedEmail === "" ? t('waiting') : t("ready")}
                className="text-center bg-[#6BCEB9] flex items-center justify-center gap-2"
                innerClassName='md:h-[28vh] h-[40vh] md:w-[75vw] w-[80vw]'
                children={
                  <div className="h-full">
                    <div className="text-sm pr-7 pl-7 text-justify h-[83%] w-full overflow-x-auto overflow-y-auto">
                      <div
                        dangerouslySetInnerHTML={{ __html: formattedEmail }} className="pt-5" id="formattedEmail"
                      />
                    </div>
                    <div className="flex items-end justify-end ">
                      <button onClick={handleCopy} className="hover:bg-black hover:bg-opacity-5 p-3 rounded-full">
                        <MdContentCopy size={22} />
                      </button>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      }
    />
    //   </div>
    // </div>
  );
};

export default Email;
