import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Auth from "./Routes/Auth";
import AppInner from "./Routes/AppInner";
import LanguageSwitcher from "./Components/LanguageSwitcher";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    localStorage.setItem("lang", "English");
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
      // toast.error("Session expired. Please login again.");
      navigate("/");
    }
  }, []);

  return (
    <>
      <LanguageSwitcher />
      <ToastContainer />
      <Routes>
        {!isAuth ? (
          <Route path="/*" element={<Auth setIsAuth={setIsAuth} />} />
        ) : (
          <Route path="/*" element={<AppInner setIsAuth={setIsAuth} />} />
        )}
      </Routes>
    </>
  );
}

export default App;
