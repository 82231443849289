import React from 'react'
import { Route, Routes } from "react-router-dom";
import SignIn from '../Pages/Auth/SignIn';
import SignUp from '../Pages/Auth/SignUp';

const Auth = ({setIsAuth}) => {
  return (
    <>
    <Routes>
      <Route index element={<SignIn setIsAuth={setIsAuth} />} />
      <Route path="/sign-up" element={<SignUp setIsAuth={setIsAuth} />} />
    </Routes>
  </>
  )
}

export default Auth