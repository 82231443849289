import React, { useState, useEffect } from "react";
import Header from "./Header";
import SectionCard from "../../Components/sectioncard";
import pdfToText from "react-pdftotext";
import { generateTranslateService } from "../../service/util";
import { FormDropdown, SearchAbleDropDown } from "../../Components/items";
import { GoUpload } from "react-icons/go";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import { FiRefreshCw } from "react-icons/fi";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js'
import { customMarkdownToHTML, removeMarkdownFormatting } from "../../utils/csv.utils";
import { Document, Packer, Paragraph, TextRun } from 'docx';


const languages = [
  { value: 'Albanian', label: 'Albanian' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Armenian', label: 'Armenian' },
  { value: 'Azerbaijani', label: 'Azerbaijani' },
  { value: 'Basque', label: 'Basque' },
  { value: 'Belarusian', label: 'Belarusian' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Bosnian', label: 'Bosnian' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Cantonese', label: 'Cantonese' },
  { value: 'Catalan', label: 'Catalan' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Croatian', label: 'Croatian' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Danish', label: 'Danish' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'English', label: 'English' },
  { value: 'Estonian', label: 'Estonian' },
  { value: 'Faroese', label: 'Faroese' },
  { value: 'Finnish', label: 'Finnish' },
  { value: 'French', label: 'French' },
  { value: 'Galician', label: 'Galician' },
  { value: 'Georgian', label: 'Georgian' },
  { value: 'German', label: 'German' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Irish', label: 'Irish' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Javanese', label: 'Javanese' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Kashmiri', label: 'Kashmiri' },
  { value: 'Kazakh', label: 'Kazakh' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Kyrgyz', label: 'Kyrgyz' },
  { value: 'Latvian', label: 'Latvian' },
  { value: 'Lithuanian', label: 'Lithuanian' },
  { value: 'Malay', label: 'Malay' },
  { value: 'Maltese', label: 'Maltese' },
  { value: 'Mandarin', label: 'Mandarin' },
  { value: 'Marathi', label: 'Marathi' },
  { value: 'Moldovan', label: 'Moldovan' },
  { value: 'Mongolian', label: 'Mongolian' },
  { value: 'Montenegrin', label: 'Montenegrin' },
  { value: 'Nepali', label: 'Nepali' },
  { value: 'Norwegian', label: 'Norwegian' },
  { value: 'Oriya', label: 'Oriya' },
  { value: 'Pashto', label: 'Pashto' },
  { value: 'Persian', label: 'Persian' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Punjabi', label: 'Punjabi' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Sanskrit', label: 'Sanskrit' },
  { value: 'Serbian', label: 'Serbian' },
  { value: 'Sindhi', label: 'Sindhi' },
  { value: 'Sinhala', label: 'Sinhala' },
  { value: 'Slovak', label: 'Slovak' },
  { value: 'Slovene', label: 'Slovene' },
  { value: 'Slovenian', label: 'Slovenian' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Uzbek', label: 'Uzbek' },
  { value: 'Vietnamese', label: 'Vietnamese' },
  { value: 'Welsh', label: 'Welsh' },
  { value: 'Wu', label: 'Wu' },
];


const Translate = ({ setIsAuth }) => {
  const [inputText, setInputText] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [loading, setLoading] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
  const { t } = useTranslation(); // Access translation function
  const [unFormattedOutputText, setUnFormattedOutputText] = useState("");


  const [isTranslationComplete, setIsTranslationComplete] = useState(false);
  useEffect(() => {
    setIsTranslationComplete(inputText?.trim() !== "" && targetLanguage !== "");
  }, [inputText, targetLanguage]);


  const handleFileUpload = (e) => {
    setTranslatedText("");
    const file = e.target.files[0];
    extractText(file);
    setIsDocumentUploaded(true);
  };

  const extractText = async (file) => {
    try {
      const text = await pdfToText(file);
      setInputText(text);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleTranslate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const data = {
        inputText,
        targetLanguage: targetLanguage,
      };
      const response = await generateTranslateService(data);
      if (response?.success) {
        setUnFormattedOutputText(response?.translatedText ?? "");
        setTranslatedText(response?.translatedText ?? "");
        toast.success("Translated successfully!");
      } else {
        let message = response?.message ?? "Failed to translate";
        toast.error(message);
        setTranslatedText("");
      }
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
      toast.error("Failed to translate");
    }
  };

  const options = {
    method: 'open',
    resolution: Resolution.HIGH,
    page: {
      margin: Margin.SMALL,
      format: 'letter',
      orientation: 'landscape',
    },
    canvas: {
      mimeType: 'image/png',
      qualityRatio: 1
    },
    overrides: {
      pdf: {
        compress: true
      },
      canvas: {
        useCORS: true
      }
    },
  };

  const handleCopy = () => {
    const emailContentDiv = document.getElementById("formattedTranslation");
    const range = document.createRange();
    range.selectNode(emailContentDiv);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    toast.success("Copied to clipboard");
  };
  const handleDownloadPDF = async () => {
    var element = document.getElementById('topdf');
    html2pdf(element);

    // const { blob, error } = await toPDF();
    // if (blob) {
    //   // Create a URL for the blob object
    //   const url = URL.createObjectURL(blob);
    //   // Create a temporary a tag to download the file
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = 'translated_document.pdf';
    //   document.body.appendChild(a);
    //   a.click();
    //   // Clean up
    //   a.remove();
    //   URL.revokeObjectURL(url);
    //   toast.success('PDF downloaded successfully!');
    // } else if (error) {
    //   toast.error('Failed to download PDF');
    //   console.error('PDF download error:', error);
    // }
  };

  const handleDownloadWord = async () => {
    const withOutMarkDown = removeMarkdownFormatting(unFormattedOutputText);
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun(withOutMarkDown),
              ],
            }),
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, 'translation.docx');
  };

  return (
    // <div className="md:bg-[#4F5AF5]">
    //   <div className="p-3 md:rounded-l-[65px] bg-[#ECF2FB] md:h-screen h-full md:w-full w-screen flex flex-col items-center overflow-hidden">
    //     <div>
    //       <Header title="Translate" setIsAuth={setIsAuth} />
    //     </div>

    <SectionCard
      title="Translate"
      innerClassName="md:w-full w-[84vw] xl:h-full h-[173vh]"
      children={
        <div className="md:flex justify-between gap-5">
          <div className="md:w-1/2 px-5 flex flex-col justify-between">
            <h1 className="text-sm font-bold mt-5">
              {t('uploadDocument')}
            </h1>
            <div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                accept=".pdf"
                onChange={handleFileUpload}
              />
              <label
                htmlFor="fileInput"
                className="w-full flex gap-2 items-center justify-center text-sm rounded-full py-2 px-10 text-white bg-[#4F5AF5] font-bold my-3 cursor-pointer"
              >
                <GoUpload />
                {
                  isDocumentUploaded ? (t('documentUploaded')) : (t('uploadDocument'))
                }
              </label>
            </div>
            <SearchAbleDropDown
              id="language"
              label={t('language')}
              value={targetLanguage}
              onChange={(event) => setTargetLanguage(event.target.value)}
              options={languages}
              placeholder={t('selectLanguage')}
              required={true}
              width="full"
              isMulti={false}
            />
            <h1 className="text-sm font-bold mt-5">
              {t('typeText')}
            </h1>
            <textarea
              className="w-full h-[27vh] resize-none border italic text-sm rounded-md mt-2 p-2"
              value={inputText}
              onChange={(event) => setInputText(event.target.value)}
            ></textarea>
            <div className="w-full h-[2px] my-4 bg-[#E2E4FB]"></div>
            <button
              className={`w-full flex gap-2 items-center justify-center text-sm rounded-full py-2 px-10 text-white bg-[#FF8203] font-bold my-3 hover:shadow-xl transition duration:300 ${isTranslationComplete
                ? ""
                : "bg-neutral-400 pointer-events-none"
                }`}
              onClick={handleTranslate}
              disabled={!isTranslationComplete}
            >
              {loading ? (
                <FiRefreshCw className="animate-spin mx-auto" />
              ) : (
                <>
                  <img src="assets/translate.png" width={25} alt="" />
                  {t('translate')}
                </>
              )}
            </button>
          </div>
          <div className="md:block hidden w-[2px] mx-5 bg-[#E2E4FB]"></div>
          {/* translation results  */}
          <div className="md:w-1/2 h-full px-3">
            <h1 className="text-sm font-bold mt-5">
              {t('translatedDocument')}
            </h1>
            <div className="flex justify-center items-center">
              <SectionCard
                iconpath={"assets/ready.png"}
                title={
                  loading ? t(
                    'translating'
                  ) : t('translate')
                }
                className="text-center bg-[#6BCEB9] flex items-center justify-center gap-2"
                innerClassName="h-[46vh] md:w-[35vw] w-[76vw]"
                children={
                  <div className="h-full">
                    <div className="text-sm pr-7 pl-7 text-justify h-[85%] overflow-y-auto" id="topdf">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: customMarkdownToHTML(translatedText),
                        }}
                        className="pt-5"
                        id="formattedTranslation"
                        ref={targetRef}
                      />
                    </div>
                    <div className="flex items-end justify-end">
                      <button onClick={handleCopy} className="hover:bg-black hover:bg-opacity-5 p-3 rounded-full">
                        <MdContentCopy size={22} />
                      </button>
                    </div>
                  </div>
                }
              />
            </div>
            <div className="w-full h-[2px] my-5 bg-[#E2E4FB]"></div>
            <div className="w-full md:flex justify-end items-end gap-3 px-3">
              <button className="px-5 w-full flex justify-between items-center py-2.5 border border-[#E2E4FB] rounded-full" onClick={handleDownloadPDF}>
                <img src="assets/download.png" width={25} alt="" />
                {t('downloadAs')} <img src="assets/pdf.png" width={25} alt="" />
              </button>
              <button className="px-8 w-full flex justify-between items-center py-2.5 border border-[#E2E4FB] rounded-full" onClick={handleDownloadWord}>
                <img src="assets/download.png" width={25} alt="" />
                {t('downloadAs')} <img src="assets/word.png" width={25} alt="" />
              </button>
            </div>
          </div>
        </div>
      }
    />
    //  </div>
    // </div> 
  );
};

export default Translate;
