import axios from "axios";
import api from "../config/api";

export const checkoutService = async (data) => {
  try {
    const { stripePriceId } = data;
    const request = await axios({
      method: "post",
      url: `${api.checkout.stripe}/${stripePriceId}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      /**
       * @response
       * @body
       * {
       *  checkout_url: "https://stripe.com/checkout",
       *  checkoutSessionId: "cs_test_a1b2c3d4e5f6",
       * }
       */
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};
