import axios from "axios";
import api from "../config/api";

export const generateEmailService = async (data) => {
  try {
    console.log("data: ", data);
    const language = localStorage.getItem("lang") || "English";
    Object.assign(data, { language });
    let request = await axios({
      method: "post",
      url: `${api.util.email}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    }).catch((err) => new Error(err));
    return request;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const generateTranslateService = async (data) => {
  try {
    /**
     * @payload
     * {
     *  inputText: "Hello",
     *  targetLanguage: "Arabic"
     * }
     */
    const request = await axios({
      method: "post",
      url: `${api.util.translate}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      /**
       * @response
       * {
       *   "message":"",
       *   "translatedText":""
       * }
       */
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
    const { response } = error;
    console.log("response: ", response);
    if (response?.status === 400) {
      return response?.data;
    }
  }
};

export const generateReportService = async (data) => {
  try {
    /**
     * @payload
     * {
     *  inputText: "Hello",
     *  targetLanguage: "Arabic",
     *  length: "Half Page"
     * }
     */
    const request = await axios({
      method: "post",
      url: `${api.util.report}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      /**
       * @response
       * {
       *   "message":"",
       *   "reportResponse":""
       * }
       */
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
    const { response } = error;
    if (response?.status === 400) {
      return response?.data;
    }
  }
};

export const generateSocialMediaService = async (data) => {
  try {
    /**
     * @payload
     *  {
     *      "selectedPlatform":",Instagram,LinkedIn,Twitter,LinkedIn",
     *      "contentIdeas":"reels",
     *      "captionsText":"branding",
     *      "responseGeneration":"ima",
     *      "fromdate":"2024-04-01 00:00:00.000",
     *      "enddate":"2024-04-26 00:00:00.000",
     *      "quantity":"2",
     *      "frequency":"monthly",
     *      "type":"videos",
     *      "themes":"lead generation"
     *  }
     */
    const lang = localStorage.getItem("lang") || "English";
    Object.assign(data, { language: lang });
    const request = await axios({
      method: "post",
      url: `${api.util.socialMedia}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      /**
       * @response
       * {
       *   "message":"",
       *   "result": [
       *        {
                "serial_number": 1,
                "date": "2024-04-09",
                "platform": "Instagram",
                "post_content": "New video showcasing the importance of mental health awareness.",
                "caption": "Your mental health matters. Let's break the stigma together. #mentalhealthawareness #breakthestigma",
                "hashtags": [
                    "mentalhealthawareness",
                    "breakthestigma"
                ],
                "upload_time": "08:00",
                "picture_idea": "A visually appealing infographic on mental health statistics"
                },
       *    ]
       * }
       */
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const generateTellMeService = async (data) => {
  try {
    /**
     * @payload
     * {
     *  "query":"Helli Hhi",
     *  "answerLength":"250"
     * }
     */

    const lang = localStorage.getItem("lang") || "English";
    Object.assign(data, { language: lang });
    const request = await axios({
      method: "post",
      url: `${api.util.tellMe}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      /**
       * {
       *    "message":"",
       *    "result":""
       *  }
       */
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};
