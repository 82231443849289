import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDropdown } from './items';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language)

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        // set the language in local storage
        if (lng === 'hu') {
            localStorage.setItem('lang', 'Hungarian');
        } else {
            localStorage.setItem('lang', 'English');
        }
        setLang(lng)
    };
    const language = [
        { value: "en", label: "English" },
        { value: "hu", label: "Hungarian" },
    ];

    useEffect(() => {
        // get the language from local storage
        changeLanguage('en')
    }, [])


    return (
        <div className="absolute top-0 right-0 mt-2 mr-3 z-50">
            <div className="relative">
                {/* <button onClick={toggleDropdown} className=" bg-orange-500 text-white px-3 py-2 rounded-md focus:outline-none">
                    Language
                </button>
                {isOpen && (
                    <ul className="absolute text-gray-800 pt-1 border border-gray-300 rounded-md bg-white w-24">
                        <li>
                            <button onClick={() => changeLanguage('en')} className="block px-4 py-2 hover:bg-gray-200 w-full text-left">
                                English
                            </button>
                        </li>
                        <li>
                            <button onClick={() => changeLanguage('hu')} className="block px-4 py-2 hover:bg-gray-200 w-full text-left">
                                Hungarian
                            </button>
                        </li>
                    </ul>
                )} */}
                <FormDropdown
                    id="language"
                    value={lang}
                    onChange={(event) => {
                        changeLanguage(event.target.value)
                    }
                    }
                    options={language}
                    placeholder="Select Language"
                    required={true}
                    className=' text-black !font-bold'
                />
            </div>
        </div>
    );
};

export default LanguageSwitcher;
