import axios from "axios";
import api from "../config/api";

export const signin = async (data) => {
  try {
    let request = await axios({
      method: "post",
      url: `${api.auth.login}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    }).catch((err) => new Error(err));
    return request;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const signUpService = async (data) => {
  try {
    const request = await axios({
      method: "post",
      url: `${api.auth.signup}`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const updatePasswordService = async (data) => {
  try {
    /**
     * @payload
     * {
     *  newPassword: "Hello",
     * }
     */
    const request = await axios({
      method: "put",
      url: `${api.user.password}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};
