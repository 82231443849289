import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import App from './App';
import './index.css';
import enTranslation from './locals/en.json';
import huTranslation from './locals/hu.json';

// Initialize i18next
i18n.init({
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  lng: 'en', // set initial language
  resources: {
    en: {
      translation: enTranslation,
    },
    hu: {
      translation: huTranslation,
    },
  },
});

// Create root and render App wrapped with BrowserRouter and I18nextProvider
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </BrowserRouter>
);
