import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";

const BarGraph = ({ data, width, height }) => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  let maxValue = Math.max(...data);
  if (maxValue > 30) {
    maxValue = Math.round(maxValue) + 6;
  } else {
    maxValue = Math.round(maxValue) + 1;
  }

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [t("human"), "Operify"],
          datasets: [
            {
              label: t("timeSpentOnTask"),
              data: data,
              backgroundColor: ["#4F5AF5", "#4F5AF5"],
              borderWidth: 1,
              borderRadius: {
                topLeft: 10,
                topRight: 10,
                bottomLeft: 10,
                bottomRight: 10,
              },
              barThickness: "80",
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: maxValue,
              title: {
                display: true,
                text: t("timeSpentOnTaskInHours"),
                font: {
                  weight: "bold",
                  size: "15",
                },
                padding: "15",
              },
              ticks: {
                stepSize: 0.5, // Step size is irrelevant here
                callback: function (value, index, values) {
                  return (value * 1).toFixed(2); // Display tick values with two decimal places
                },
              },
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, width, height]);

  return <canvas ref={chartRef} width={width} height={height} />;
};

export default BarGraph;
