import React, { useRef, useEffect } from "react";
import BarGraph from "./BarGraph";
import { useTranslation } from 'react-i18next';


const HomeModal = ({ title, onClose, graphData }) => {
  const modalRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  const barGraphData = graphData ?? [0, 0];
  const screenWidth = window.innerWidth;

  // Define width and height based on screen width
  let width = 500;
  let height = 300;
  if (screenWidth <= 640) {
    // For mobile screens or smaller
    width = 300;
    height = 200;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-black opacity-55"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalRef} className="inline-block align-middle bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full h-1/2">
          <div className="flex flex-col items-center justify-evenly text-center h-full">
            <h3 className="text-lg leading-6 text-gray-900 text-center font-semibold">{t(title)} ({t("humanVsOperify")})</h3>
            <div className="text-center sm:text-left">
              <div>
                <BarGraph data={barGraphData} width={width} height={height} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeModal;