import React, { useState, useEffect } from "react";
import { FormCheckbox, FormInput } from "../../Components/items";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { signin } from "../../service/auth";
import { FiRefreshCw } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const SignIn = ({ setIsAuth }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (form.email === "" || form.password === "") setError(true);
    else {
      setError(false);
      try {
        let payload = {
          email: form.email,
          password: form.password,
        };
        let { data } = await signin(payload);
        if (data.token) {
          toast.success("Logged In !")
          localStorage.setItem("token", data?.token);
          setIsAuth(true);
        } else {
          toast.error("Invalid email or password. Retry!");
        }
      } catch (error) {
        setError(true);
        console.log(error);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsAuth(true);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="md:flex h-screen w-full">
        <div className=" hidden bg-[#4F5AF5] md:w-1/2 h-full md:flex items-center justify-center">
          <img src="assets/logoSignup.png" alt="AI Development" width={480} />
        </div>
        <div className="md:bg-[#4F5AF5] md:w-1/2  h-screen flex flex-col justify-center items-center">
          <div className="bg-white md:border md:rounded-l-[40px] md:w-full h-screen flex flex-col justify-center items-center ">
            <div className="text-left space-y-12">
              <h1 className="text-4xl font-semibold">{t("welcomBack!")}</h1>
              <form onSubmit={handleLogin}>
                <FormInput
                  id="email"
                  value={form.email}
                  placeholder={t("enterEmail")}
                  onChange={(e) => { setForm((form) => ({ ...form, email: e.target.value })) }}
                  required
                />
                <FormInput
                  type="password"
                  id="password"
                  value={form.password}
                  placeholder={t("enterPassword")}
                  onChange={(e) => { setForm((form) => ({ ...form, password: e.target.value })) }}
                  required
                />
                <FormCheckbox
                  id="remember"
                  label={t("rememberMe")}
                  onChange={() => { }}
                />
                <button
                  type="submit"
                  className="bg-[#4F5AF5] text-white text-center py-2.5 rounded-full w-full font-bold mt-5"
                >
                  {
                    loading ? <FiRefreshCw className="animate-spin mx-auto" /> : t("logIn")
                  }
                </button>
              </form>
              <p className="text-sm">
                {t("don'tHaveAnAccount?")}{" "}
                <Link to="/sign-up" className="text-violet-600 font-medium">
                  {t("signUp")}
                </Link>
              </p>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default SignIn;
