import axios from "axios";
import api from "../config/api";

/**
 * API call to fetch user details
 */
export const fetchUserDetailsService = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return null;
    const request = await axios({
      method: "get",
      url: `${api.user.details}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
    });
    if (request?.status === 200) {
      const { data } = request;
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

/**
 * API call to fetch history
 */
export const fetchHistoryService = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return null;
    const request = await axios({
      method: "get",
      url: `${api.user.history}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
    });
    if (request?.status === 200) {
      const { data } = request;
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

/**
 * API call to fetch plans
 */

export const fetchPlansService = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return null;
    const request = await axios({
      method: "get",
      url: `${api.user.plans}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
    });
    if (request?.status === 200) {
      const { data } = request;
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const updateProfileService = async (data) => {
  try {
    const request = await axios({
      method: "put",
      url: `${api.user.update}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const updatePasswordService = async (data) => {
  try {
    /**
     * @payload
     * {
     *  newPassword: "Hello",
     * }
     */
    const request = await axios({
      method: "put",
      url: `${api.user.password}`,
      headers: {
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (request?.status === 200) {
      const { data } = request;
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};
