import React, { useRef, useState, useEffect } from "react";
import { Modal } from "@material-tailwind/react";
import { Chip } from "@material-tailwind/react";
import Header from "./Header";
import { FormDropdown } from "../../Components/items";
import SectionCard from "../../Components/sectioncard";
import { DashboardInput, FormInput } from "../../Components/items";
import Plans from "../../Components/plansModal";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { fetchUserDetailsService, updatePasswordService, updateProfileService } from "../../service/user";
import { toast } from "react-toastify";
import api from "../../config/api";
const Loader = require('react-loader');


const Profile = ({ setIsAuth }) => {

  const { t } = useTranslation(); // Access translation function

  const [chips, setChips] = useState([]);
  const [form, setForm] = useState({
    fullName: "",
    tagLine: "",
    companyName: "",
    noEmp: "",
    age: "",
    location: "",
    industry: "",
    audience: "",
    seoKeywords: "",
    goal: "",
    newPassword: "",
    profilePicture: "",
    products: []
  });
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [currentPlanId, setCurrentPlanId] = useState("")

  const handleChipChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newChip = { value: inputValue.trim(), label: inputValue.trim() };
      setChips([...chips, newChip]);
      setInputValue('');
    }
  };

  const handleChipRemove = (chipToRemove) => {
    setChips(chips.filter(chip => chip.value !== chipToRemove.value));
  };

  const handleSave = () => {
    setForm(prevForm => ({
      ...prevForm,
      products: chips
    }))
    console.log(chips);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setInputValue('');
  };



  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  const fileInputRef = useRef(null);

  const handleImageUpload = async (event) => {
    const { REACT_APP_CLOUD_NAME, REACT_APP_UPLOAD_PRESET } = api.cloudinary;
    if (!REACT_APP_CLOUD_NAME || !REACT_APP_UPLOAD_PRESET) {
      console.error("Cloudinary environment variables not found");
      toast.error("Error uploading image.");
      return;
    }

    const files = event.target.files;
    if (files.length === 0) {
      toast.error("No file selected.");
      return;
    }

    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", REACT_APP_UPLOAD_PRESET);

    try {
      const API_URL = `https://api.cloudinary.com/v1_1/${REACT_APP_CLOUD_NAME}/image/upload`;
      const response = await fetch(API_URL, {
        method: "POST",
        body: data
      });
      const file = await response.json();

      if (file.secure_url) {
        toast.success("Image uploaded successfully!");
        setForm(prevForm => ({
          ...prevForm,
          profilePicture: file.secure_url
        }));
        form.profilePicture = file.secure_url;
        await updateProfile();
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image.");
    }
  };


  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState("");


  const handleInputChange = (fieldName) => (event) => {
    const selectedValue = event.target.value;
    setForm(prevForm => ({
      ...prevForm,
      [fieldName]: selectedValue,
    }));
  };

  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setForm((prevForm) => ({
  //         ...prevForm,
  //         imgURL: reader.result,
  //       }));
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const updateProfile = async () => {
    const response = await updateProfileService(form);
    if (response) {
      toast.success("Profile updated successfully");
      await fetchProfile();
    } else {
      toast.error("Failed to update profile");
    }
  }




  const fetchProfile = async () => {
    try {
      const data = await fetchUserDetailsService();
      setForm(data);
      setChips(data.products);
      setCurrentPlanId(data.planId)
    } catch (error) {
      console.error('Failed to fetch profile data:', error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchProfile();
  }, []);

  const updatePassword = async () => {
    if (!password?.length) {
      toast.error("Please enter a password");
      return;
    }
    const data = {
      newPassword: password,
    }

    const response = await updatePasswordService(data);
    if (response) {
      setPassword("");
      toast.success("Password updated successfully");
    } else {
      toast.error("Failed to update password");
    }
  }


  var options = {
    lines: 13,
    length: 7,
    width: 4,
    radius: 10,
    scale: 1.00,
    corners: 1,
    color: "#4F5AF5",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
  };

  if (isLoading) {
    return (
      // <div className="md:bg-[#4F5AF5]">
      //   <div className="p-3 md:rounded-l-[65px] bg-[#ECF2FB] md:h-screen flex flex-col items-center">
      //     <div>
      //       <Header title="Profile Page" setIsAuth={setIsAuth} />
      //     </div>
      <div className="flex justify-center items-center h-full">
        <Loader loaded={false} options={options} className="spinner" />
      </div>
      //   </div>
      // </div>
    )
  }

  return (
    <>


      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImageUpload}
        accept="image/*"
      />

      <SectionCard
        title={t('personalAccount')}
        className="flex bg-[#4F5AF5]"
        children={
          <div className="px-5 py-5 overflow-y-auto h-[70vh]">
            <p className="text-sm font-bold">
              {t('profile')}
            </p>
            <div className="flex justify-between">
              <div className="flex justify-between items-center gap-3">
                <div className="">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none", cursor: "pointer" }}
                    id="image-upload"
                  />
                  <label htmlFor="image-upload">
                    <div className="relative w-20 h-20 rounded-full overflow-hidden bg-white p-1">
                      {form.profilePicture ? (
                        <img
                          src={form.profilePicture ?? 'assets/dummy-pofile.webp'}
                          alt="Uploaded"
                          className="w-full h-full object-cover rounded-full cursor-pointer"
                        />
                      ) : (
                        <div className="rounded-full bg-[#F3EFF7] p-5 w-full h-full"></div>
                      )}
                    </div>
                  </label>
                </div>
                <div className="my-5">
                  <h1 className="md:text-3xl text-2xl font-regular pb-2">
                    {form?.name ?? ''}
                  </h1>
                  <p className="font-regular text-lg text-gray-500">
                    {form?.bio ?? ''}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <button onClick={handleModalToggle} className="px-6 py-2 items-center bg-[#F3EFF7] rounded-full text-sm text-violet-800 shadow hover:bg-violet-900 hover:bg-opacity-10 transition duration:300">
                  {t('changePlan')}
                </button>
              </div>
            </div>
            <div className="w-full h-[1px] bg-[#E2E4FB]"></div>
            <div className="md:flex justify-between gap-3 py-3">
              <DashboardInput
                label={t('fullName')}
                placeholder={form?.name ?? ''}
                value={form.fullName}
                onChange={handleInputChange("fullName")}
              />
              <DashboardInput
                label={t('tagline')}
                placeholder={form.bio}
                value={form.tagLine}
                onChange={handleInputChange("tagLine")}
              />
              <DashboardInput
                label={t('companyName1')}
                placeholder={form?.company?.name ?? ''}
                value={form.companyName}
                onChange={handleInputChange("companyName")}
              />
              <DashboardInput
                label={t('numOfEmp')}
                placeholder={form?.company?.noEmp ?? ''}
                value={form.noEmp}
                onChange={handleInputChange("noEmp")}
              />
            </div>
            <div className="w-full h-[1px] bg-[#E2E4FB]"></div>
            <div className="py-3">
              <p className="text-md font-bold">Target Audience</p>
              <div className="md:flex justify-between gap-3 py-2">
                <DashboardInput
                  label={t('ageGrp')}
                  placeholder={form?.targetAudience?.age}
                  value={form.age}
                  onChange={handleInputChange("age")}
                />
                <DashboardInput
                  label={t('location')}
                  placeholder={form?.targetAudience?.location}
                  value={form.location}
                  onChange={handleInputChange("location")}
                />
                <DashboardInput
                  label={t('industry')}
                  placeholder={form.targetAudience.industry}
                  value={form.industry}
                  onChange={handleInputChange("industry")}
                />
                <DashboardInput
                  label={t('audience')}
                  placeholder={form.targetAudience.audience}
                  value={form.audience}
                  onChange={handleInputChange("audience")}
                />
              </div>
            </div>
            <div className="w-full h-[1px] bg-[#E2E4FB]"></div>
            <div className="flex justify-between py-3">
              <div className="md:flex justify-between gap-[4.2vw]">
                <DashboardInput
                  label={t('seoKeywords')}
                  placeholder={form.targetAudience.seoKeywords}
                  value={form.seoKeywords}
                  onChange={handleInputChange("seoKeywords")}
                />
                <DashboardInput
                  label={t('goals')}
                  placeholder={form.targetAudience.goal}
                  value={form.goal}
                  onChange={handleInputChange("goal")}
                />
              </div>
              <div className="flex items-center">
                <button className="px-4 py-2 bg-[#4F5AF5] text-white rounded-md" onClick={() => setShowModal(true)}>{t('updateProducts')}</button>

                {showModal && (
                  <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-10">
                    <div className="bg-white p-8 rounded-lg md:w-[50vw] lg:w-[40vw] xl:w-[30vw] 2xl:w-[25vw]">
                      <div className="flex flex-col gap-4">
                        <h2 className="text-xl font-semibold">{t('addServices/Products')}</h2>
                        <div className="flex flex-col justify-between">
                          <input
                            type="text"
                            value={inputValue}
                            onChange={handleChipChange}
                            onKeyPress={handleKeyPress}
                            placeholder={t('typeAndPressEnterToAdd')}
                            className="border border-gray-300 rounded-lg px-3 py-2 mb-4 md:mb-2 w-full"
                          />
                          <div className="chips overflow-y-auto max-h-[25vh] flex flex-wrap gap-2">
                            {chips.map((chip, index) => (
                              <div key={index} className="chip bg-gray-200 rounded-lg px-3 py-2 flex items-center">
                                <span className="mr-2">{chip.label}</span>
                                <button onClick={() => handleChipRemove(chip)} className="text-gray-600 hover:text-gray-800">
                                  x
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="flex justify-end gap-4">
                          <button onClick={handleCloseModal} className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
                            {t('cancel')}
                          </button>
                          <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            {t('save')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center">
                <button
                  className={`rounded-full flex items-center gap-2 bg-orange-500
                        py-2 px-8 text-white text-sm font-semibold hover:shadow-xl transition duration:300`}
                  onClick={updateProfile}
                >
                  {t('save')}
                </button>
              </div>
            </div>

            <div className="w-full h-[1px] bg-[#E2E4FB]"></div>
            <div className="flex gap-10 py-3 items-center">
              <FormInput
                label={t('password')}
                placeholder="*********"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <div className="cursor:pointer flex items-center">
                <button
                  className={`rounded-full flex items-center gap-2 bg-neutral-500
                        py-2 px-8 text-white text-sm font-semibold hover:shadow-xl transition duration:300`}
                  onClick={updatePassword}
                >
                  {t('changePassword')}
                </button>
              </div>
            </div>
          </div>
        }
      />
      <Plans isOpen={isModalOpen} onClose={handleModalToggle} currentPlanId={currentPlanId} />

    </>
  );
};

export default Profile;